var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'bulk-selection-wrapper',
      selectionText: 'groups selected',
      clearSelectionText: 'Clear Selections',
    },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.bulkSelectionChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('div',{staticClass:"justify-content-between flex-wrap vgt-center-align",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-0"},[_c('action-button',{staticClass:"nav",attrs:{"variant":"outline-none"},on:{"click":function($event){return _vm.archiveGroup(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon","size":"20"}})],1)],1)]):_vm._e(),(props.column.field === 'name')?_c('div',{staticClass:"justify-content-between flex-wrap vgt-center-left",attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{ name: 'champion-participants-filter-groups', params: { id: _vm.defaultProgramId, groupId: props.row.id } }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])}),_c('b-modal',{attrs:{"id":"updating-modal","hide-footer":"","hide-header":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('div',{staticClass:"d-block text-center"},[_c('b-spinner',{staticClass:"mt-2",attrs:{"medium":"","variant":"primary"}}),_c('h5',{staticClass:"mt-2"},[_vm._v(" Updating... ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }