<template>
  <b-card>  
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'bulk-selection-wrapper',
        selectionText: 'groups selected',
        clearSelectionText: 'Clear Selections',
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="bulkSelectionChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <div
          v-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="d-flex align-items-center mb-0 mt-0"> 
            <action-button
              variant="outline-none"
              class="nav"
              style=""
              @click="archiveGroup(props.row.id)"
            >
              <feather-icon
                icon="ArchiveIcon"
                size="20"
              />
            </action-button>
          </div>
        </div> 
        <div
          v-if="props.column.field === 'name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-left"
        >
          <router-link
            class="table-primary-link"
            :to="{ name: 'champion-participants-filter-groups', params: { id: defaultProgramId, groupId: props.row.id } }"
          >
            {{ props.row.name }}
          </router-link>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
  
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <b-modal
      id="updating-modal"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <b-spinner
          medium
          variant="primary"
          class="mt-2"
        />
        <h5 class="mt-2">
          Updating...
        </h5>
      </div>
    </b-modal>
  </b-card>
</template>
  
<script>
import {
  BButton,
  BCard,
  BModal,
  BSpinner
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import groupsService from "@/services/groupsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { groupTypeSelect } from '@models';
import { mapGetters } from 'vuex';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
    VueGoodTable,
    BButton,
    BCard,
    TablePagination,
    BModal,
    BSpinner,
  },
  data() {
    return {
      showArchived: true,
      isLoading: false,
      element : { },
      dir: false,
      bulkSelection: [],
      columns: [
        {
          label: "Group",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Group",
          },
          width: "8em",
        },
        {
          label: "Type",
          field: "type.name",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: groupTypeSelect,
          },
          width: "10em",
        },
        {
          label: "Number of participants",
          field: "users_count",
          filterOptions: {
            enabled: true,
            placeholder: "Number of participants",
          },
          width: "10em",
        },       
        {
          field: "action",
          width: "8em",
          sortable: false
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    async archiveGroup(groupId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to archive this group? The group will be removed from any communication or survey they are a recipient of. Pools will not be affected until you refresh them.', {
          title: 'Archive Group?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Archive',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$bvModal.show('updating-modal');
              const programId = this.$route.params.id;
              const response = await groupsService.deleteGroup(programId, groupId);
              if(response) {
                this.$toast(makeSuccessToast('Group archived successfully.'));
                this.loadItems();
              }             
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Group not deleted.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });            
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
  
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "name", 
        "type.name",
        "users_count"
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'type.name'){
            columnFilters.push({
              field: 'type',
              value: params.columnFilters['type.name'],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
          
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await groupsService.getList(programId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const {total, items} = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    bulkSelectionChange(args) {
      const { selectedRows } = args;
      if(Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
        this.$emit("groupsSelected", this.bulkSelection);
      }
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  },  
};
</script>
  
<style lang="scss" scoped>
  tbody > tr > td {
    vertical-align: middle !important;
  }
  
  tr > td {
    vertical-align: middle !important;
  }
  
  .badge {
    font-weight: 400 !important;
  }
</style>
  
<style lang="scss">
  @import "/src/assets/scss/vue-good-table.scss";
</style>
  